.font-size-button{
    height: 50px;
    width: 100%;
    background: #D7000F;
    color: #FFFFFF;
    border: solid 1px #D7000F;
    border-radius: 6px;
}
.responsiveIP-x{
    margin-bottom: 1.5rem !important;
}
.fix-max-height {
    max-height: 85%;
    overflow: auto;
}
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    .responsiveIP-x{
        margin-bottom: 1.5rem !important;
    }
 }
