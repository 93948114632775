.data-list {
  border-bottom: solid 2px #d7000f !important;
  color: #d7000f !important ;
}
.system-list {
  border-bottom: solid 2px #d7000f !important;
  color: #d7000f !important ;
}
.sensor-err-list {
  border-bottom: solid 2px #d7000f !important;
  color: #d7000f !important ;
}
.data-list-button {
  width: 70;
  height: 20;
  padding: "1rem 1.5rem";
  text-transform: "unset";
  background-color: "#F21100";
  color: "#FFFFFF";
}
.show-more-data-list .row {
  margin-top: 1.5rem;
  margin-left: unset;
  margin-right: unset;
  padding-right: 0.2rem;
  padding-bottom: 0.5rem;
}

.custom-detail {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.maxHeight64 {
  max-height: 64px;
}
.maxHeight75 {
  max-height: 75px;
}
.responsive-img-alert-sensort {
  width: 50px;
  height: 50px;
}
@media screen and (max-width: 539px) {
  .responsive-img-alert-sensort {
    width: 35px;
    height: 35px;
  }
}
