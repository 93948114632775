.nishimu-H_90x{
    height: 90px;
    /* min-width: 113px; */
}
.nishimu-H_100x{
    height: 100px;
}
.fontSize-button-save .row{
    margin-left: unset;
    margin-right: unset;
}
@media only screen  and (max-height: 812px) {
    .response{
        z-index: unset; 
        width: 100%;
        overflow: scroll;
    }
}
@media only screen and (min-width: 320px) and (max-height: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    .response{
        z-index: unset; 
        width: 100%;
        overflow: scroll;
    }
}

.setting-fixed::-webkit-scrollbar:vertical {
    width: 0px;
}
@media only screen and (min-width: 768px){
    .setting .sensorRegisterEn,.weatherInformationEn,.noteManagementEn{
      margin-bottom: 0.5rem;
    }
}
@media only screen and (min-width: 540px)  and (max-height: 720px){
    .setting .sensorRegisterEn,.weatherInformationEn,.noteManagementEn{
      margin-bottom: 0.5rem;
    }
}