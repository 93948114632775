.form-group label {
    font-weight: normal;
}
.react-datepicker-wrapper {
    position: relative !important;
    flex: 1 1 auto !important;
    width: 1% !important;
}
.custom-file-label {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    padding-right: 85px;
}
.badge-error {
    background-color: #ffc902;
}

.main-menu.menu-dark .navigation > li > a i,
.main-menu.menu-light .navigation > li > a i {
    top: 2px;
}
.form-control {
    color: #3a3a3a !important;
}

.width-child-textbox {
    width: calc(100% - 75px);
}

@media (max-width: 992px) {
    .logo-text-style {
        font-size: 24px !important;
    }

    .main-menu.menu-dark .navigation > li > a i,
    .main-menu.menu-light .navigation > li > a i {
        top: 0px;
    }
}

.custom-file-input1::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input1::before {
    content: 'Select some files';
    display: inline-block;
    background: linear-gradient(to bottom, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input1:hover::before {
    border-color: black;
  }
  .custom-file-input1:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }