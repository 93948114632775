.div-content {
    flex: 1;
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.div-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.view-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    padding: 0 15px;
}

.btn {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    height: 50px;
    text-align: center;
    line-height: 0px;
    background-color: #D7000F !important;
    border: 1px solid #D7000F !important;
    border-radius: 6px;
}

.btn:hover {
    background-color: #D7000F !important;
    border: 1px solid #D7000F !important;
}

.btn::selection {
    background-color: #D7000F !important;
    border: 1px solid #D7000F !important;
}

.btn:focus {
    background-color: #D7000F !important;
    border: 1px solid #D7000F !important;
}

.btnLeft {
    margin-right: 35px;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    height: 50px;
    text-align: center;
    line-height: 0px;
    background-color: transparent !important;
    border: 1px solid #D7000F !important;
    border-radius: 6px;
    color: #D7000F !important;
}

.btnLeft:hover {
    background-color: transparent !important;
    border: 1px solid #D7000F !important;
    color: #D7000F !important;
    outline: none;
}

.btnLeft::selection {
    background-color: transparent !important;
    border: 1px solid #D7000F !important;
    color: #D7000F !important;
    outline: none;
}

.btnLeft:focus {
    background-color: transparent !important;
    border: 1px solid #D7000F !important;
    color: #D7000F !important;
    outline: none;
}

.view-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.select-df {
    border-radius: 6px;
    padding: 5px 10px;
    height: auto;
    max-height: 36px;
    border: 1px solid #E4E9F0;
}

.select-dark {
    border-radius: 6px;
    padding: 5px 10px;
    height: 28px;
    border: 1px solid #707070;
}

.view-ip-st {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 15px;
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.view-state {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 10px 18px;
    border-radius: 6px;
}

.view-state-df {
    border: 1px solid #E4E9F0;
}

.view-state-dark {
    border: 1px solid #707070;
}

.view-ip-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.view-ip-child p {
    margin-bottom: 9px;
}

.view-ip-child .input-group input{
    height: 35px !important;
}

.br-left {
    padding-right: 10px;
    margin-right: 15px;
}

.br-right {
    padding-left: 10px;
}

.view-lb-state {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 20px;
}

.lb-state {
    min-width: 100px;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: #FFC0C0;
    color: #D7000F;
}

.div-br {
    width: 100%;
    height: 1px;
    margin: 18px 0 15px;
}

.error-p {
    font-size: 80%;
    color: #D7000F;
    margin-top: 5px;
    margin-bottom: 0 !important;
}

.ip-br-err input {
    border: 1px solid #ff4961
}

 /* Dialog */
.root {
    flex-grow: 1,
}

.bottomDialog {
    bottom: 25px !important
}

.paddingDialogContent {
    padding: 32px 16px;
    border-radius: 6px;
    display: flex;
    flex-direction: column
}

.mt1_5re {
    margin-top: 1.5rem;
    margin-bottom: 0;
}

.alignItemCenter {
    align-items: center
}

.customTy{
    text-align: center
}

.TyTextwhite {
    color: "#FFFFFF"
}
/* list */
.btnGoToCreate {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnGoToCreate img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
}

.view-list-scroll {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 15px 3px;
    height: 65vh;
    overflow: auto;
    margin-top: 10px;
}

.view-list {
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.data-line-1 {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.btn-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-radius: 6px;
    width: 72px;
    border: 0;
    color: #FFF;
}

.btn-item img{
    width: 14px;
    height: 14px;
    margin-right: 5px;
}

.btn-edit {
    background-color: #FF9F0F;
    border-color: #FF9F0F;
}

.btn-del {
    background-color: #D7000F;
    border-color: #D7000F;
}

.data-line-2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 5px;
}

.data-line-2 p {
    margin-bottom: 0;
    letter-spacing: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.data-line-2-p1 {
    width: 100%;
    display: flex;
}

.data-line-2-p2 {
    margin-left: 10px;
    width: 50%;
}

.data-line-2-p3 {
    width: 30%;
    max-width: 30%;
}

.link-detail {
    text-decoration: underline;
    color: #2196F3;
    font-weight: 600;
    word-break: break-all;
}
/** Config **/
.view-st {
    /* margin-top: 30px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}
.view-switch {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center
}
.view-ip-cf {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 6px;
    background-color: #FFF;
}
.view-ip-cf-dark {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 6px;
    background-color: #353441;
}
.view-row-cf {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
}

.grid-ip-cf-18 .input-group input{
    height: 32px !important;
}

.grid-ip-cf .input-group input{
    height: 29px !important;
}

.view-ip-dis {
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: 29px;
    width: 100%;
    border-radius: 6px;
    background-color: #eee;
}

.hanld-btn-add button.auth{
    height: 40px;
}

.btnDelSt {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px !important;
    padding: 0px !important;
}

.btnDelSt img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.error-p-cus {
    margin-top: -5px;
    margin-bottom: 10px !important;
}