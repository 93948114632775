.line-darkBackground {
  background-color: white;
}

.line-lightBackground {
  background-color: #e5e5e5;
}
.gm-style-iw {
  padding: 0px !important;
  max-width: 270px !important;
}
.gm-ui-hover-effect {
  opacity: 0 !important;
}
.gm-style-iw-d {
  overflow: hidden !important;
}

a[href^="http://maps.google.com/maps"],
a[href^="https://maps.google.com/maps"]{
  display:none !important
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
    display:none;
}

.gmnoprint div {
    background:none !important;
}
.fz-18-map-width{
  width: 270px;
}
.fz-16-map-width{
  width: 260px;
}
.custom-iw::after {
  background:#282735 !important;
}
.custom-iw .gm-style-iw-c {
  background-color:#282735 !important;
}