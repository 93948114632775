.tab-sensor-type-main {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin-top: -15px;

  margin-bottom: 10px;
}

.tab-sensor-type {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.tab-active {
  border-bottom: 3px solid #D7000F;
}

.tab-active-mhr {
  border-bottom: 3px solid limegreen;
}

.img-type {
  height: 35px;
  width: 35px;
  margin-bottom: 5px;
}

.text-active {
  color: #37333E;
}

.text-active-dark {
  color: white;
}

.text-unactive {
  color: #A8ABB8 !important
}