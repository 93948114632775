.card-group{
    width: 100%;
}
.group-scroll{
    height: 120px;
    overflow: scroll;
}
.group-btn-save {
    margin-top: 1rem;
}
.group-btn-save .row{
    margin-left:0;
    margin-right: 0;
}
.MuiOutlinedInput-notchedOutline{
    border-color:rgba(0, 0, 0, 0.23)  !important;
    border-width: 1px !important;
}
.padding-left_15x{
    padding-left: 15px;
}
.button-disable button:disabled{
   opacity: 0.6;
}


