.div-content {
    flex: 1;
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.div-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.view-ip-position {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.view-ip-position img{
    margin-top: -42px;
    margin-bottom: 15px;
    margin-left: 15px;
    height: 27px;
    width: 30px;
    z-index: 1;
}

.sty-img-err { 
    margin-top: -58px !important;
    margin-bottom: 31px !important;
}

.view-ip-position input{
    padding-left: 55px;
}

.select-sl-fix {
    border-radius: 4px;
    height: 52px;
}

.select-bg-df {
    border: 1px solid #E4E9F0;
    background: url('../../../assets/images/icon_dropdown_light.png') right 1rem center/19px 10px no-repeat #fff;
}

.select-bg-dark {
    border: 0;
    background: url('../../../assets/images/icon_dropdown_dark.png') right 1rem center/19px 10px no-repeat #fff;
}

.view-btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    padding: 0 15px;
}

.btnLeftAddSs {
    margin-right: 35px;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    min-height: 50px;
    text-align: center;
    background-color: transparent !important;
    border: 1px solid #D7000F !important;
    border-radius: 6px;
    color: #D7000F !important;
}

.btnLeftAddSs:hover {
    background-color: transparent !important;
    border: 1px solid #D7000F !important;
    color: #D7000F !important;
    outline: none;
}

.btnLeftAddSs::selection {
    background-color: transparent !important;
    border: 1px solid #D7000F !important;
    color: #D7000F !important;
    outline: none;
}

.btnLeftAddSs:focus {
    background-color: transparent !important;
    border: 1px solid #D7000F !important;
    color: #D7000F !important;
    outline: none;
}

.btnRightAddSs {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    min-height: 50px;
    text-align: center;
    background-color: #D7000F !important;
    border: 1px solid #D7000F !important;
    border-radius: 6px;
}

.btnRightAddSs:hover {
    background-color: #D7000F !important;
    border: 1px solid #D7000F !important;
}

.btnRightAddSs::selection {
    background-color: #D7000F !important;
    border: 1px solid #D7000F !important;
}

.btnRightAddSs:focus {
    background-color: #D7000F !important;
    border: 1px solid #D7000F !important;
    outline: none;
}