.mb-10x {
  margin-bottom: 10px;
}
.mb-20x {
  margin-bottom: 20px;
}
.scroll-list-sensor-by-group {
  overflow: auto;
  height: calc(100% - 5px);
}
.break-word {
  word-break: break-word;
}
