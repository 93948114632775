.custom-select-lb {
    display: flex;
    align-items: center;
}

.custom-select-lb .css-b62m3t-container {
    width: 100%;
}

.custom-select-lb .css-b62m3t-container .css-1s2u09g-control {
    /* border-width: 0px; */
}

.custom-select-lb .css-b62m3t-container .css-1pahdxg-control {
    /* border-width: 0px; */
}

.custom-select-lb .css-b62m3t-container .css-1s2u09g-control .css-1hb7zxy-IndicatorsContainer {
    display: none;
}

.custom-select-lb .css-b62m3t-container .css-1pahdxg-control .css-1hb7zxy-IndicatorsContainer {
    display: none;
}

.custom-select-lb-dark .css-b62m3t-container .css-1s2u09g-control {
    background-color: #353441 !important;
    color: #FFFFFF !important;
}

.custom-select-lb-dark .css-b62m3t-container .css-1pahdxg-control {
    background-color: #353441 !important;
    color: #FFFFFF !important;
}

.custom-select-lb-dark .css-26l3qy-menu {
    background-color: #353441 !important;
    color: #FFFFFF !important;
}

.custom-select-lb-dark .css-b62m3t-container .css-1s2u09g-control .css-319lph-ValueContainer .css-qc6sy-singleValue {
    color: #FFFFFF !important;
}

.custom-select-lb-dark .css-b62m3t-container .css-1pahdxg-control .css-319lph-ValueContainer .css-qc6sy-singleValue {
    color: #FFFFFF !important;
}

.custom-select-lb-dark .css-b62m3t-container .css-1pahdxg-control .css-319lph-ValueContainer .css-1pndypt-Input {
    color: #FFFFFF !important;
}

.custom-select-lb-dark .css-b62m3t-container .css-1s2u09g-control .css-319lph-ValueContainer .css-6j8wv5-Input {
    color: #FFFFFF !important;
}

.custom-select-lb-dark .css-b62m3t-container .css-1s2u09g-control 
    .css-319lph-ValueContainer .css-6j8wv5-Input input{
    color: #FFFFFF !important;
}

.custom-select-lb-dark .css-b62m3t-container .css-1pahdxg-control .css-319lph-ValueContainer .css-6j8wv5-Input {
    color: #FFFFFF !important;
}

.custom-select-lb-dark .css-b62m3t-container .css-1pahdxg-control
    .css-319lph-ValueContainer .css-6j8wv5-Input input{
    color: #FFFFFF !important;
}

.custom-select-lb-dark .css-b62m3t-container .css-1s2u09g-control 
    .css-1d8n9bt .css-ackcql input {
    color: #FFFFFF !important;
}

.custom-select-lb-dark .css-b62m3t-container .css-1pahdxg-control 
    .css-1d8n9bt .css-ackcql input {
    color: #FFFFFF !important;
}

.custom-select-lb-dark .css-b62m3t-container .css-1pahdxg-control 
    .css-1d8n9bt .css-vwja0k input {
    color: #FFFFFF !important;
}

.custom-select-lb .css-b62m3t-container .css-1s2u09g-control .css-1wy0on6 {
    display: none;
}

.custom-select-lb .css-b62m3t-container .css-1pahdxg-control .css-1wy0on6 {
    display: none;
}