.node-W__50 {
  width: 50%;
}
.node-W__80 {
  width: 80%;
}
.node-W__20 {
  width: 20%;
}
.node-W__70 {
  width: 70%;
}
.node-W__30 {
  width: 30%;
}
.node-position {
  position: absolute;
  top: 42%;
}
.fs-1_5e {
  font-size: 1.5em;
}
.node-icon-action-pd {
  padding: 0.25em 0.25em;
}
.icon-cls__FF9F0F {
  color: #ff9f0f;
}
.icon-cls__F21100 {
  color: #f21100;
}
.node-fw__600 {
  font-weight: 600;
}
.node-bdr__1x {
  border-right: solid 1px #e5e5e5;
}
.node-ml__0_25e {
  margin-left: 0.25em;
}
/*style items (options):*/
.ddown {
    width: 50%;
}

.dd-item {
    width: 100%;
}

.select-icon-bgSelectDf{
  background: url('assets/images/icon_dropdown_light.png') right 1rem center/10px 8px no-repeat #fff;
}