.footer-df {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 15px !important;
  box-shadow: 0px 0px 20px rgba(209, 217, 230, 0.25);
  position: fixed;
  width: 100%;
  max-width: 720px;
  bottom: 0;
  z-index: 999;
}

.footer-df p {
  margin: 0;
}

.icon-ft {
  width: "auto";
  height: 25px;
}

.div-slt {
  width: 60px;
  height: 60px;
  background: #ebebeb;
  box-shadow: 4px 4px 5px #d1d9e6, -4px -4px 5px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.div-slt-dark {
  width: 60px;
  height: 60px;
  background: #333340;
  box-shadow: 4px 4px 5px #181a1e, -4px -4px 5px rgba(24, 26, 30, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.div-df {
  width: 60px;
  height: 60px;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-selected {
  background: -webkit-linear-gradient(
    62.3deg,
    #d7000f 3.51%,
    #eb4519 65.53%,
    #ea7000 100.32%
  );
  background: -moz-linear-gradient(
    62.3deg,
    #d7000f 3.51%,
    #eb4519 65.53%,
    #ea7000 100.32%
  );
  background: linear-gradient(
    62.3deg,
    #d7000f 3.51%,
    #eb4519 65.53%,
    #ea7000 100.32%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fz-12 {
  font-size: 12px;
}
.fz-14 {
  font-size: 14px;
}
.fz-16 {
  font-size: 16px;
}
