* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

body {
  background-color: #e6e6e6;
}

.tabs {
  width: 100%;
  z-index: 0;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  box-shadow: 0px 4px 4px rgba(209, 217, 230, 0.35);
}

.header-shadow-df {
  box-shadow: 0px 4px 4px rgba(209, 217, 230, 0.35);
}

.header-shadow-dark {
  box-shadow: 0px -2px 14px 1px rgba(0, 0, 0, 0.35);
}

.header-mhr {
  background-color: #D7000F;
  box-shadow: none;
}

.select-icon-bgSelectDf {
  /* Add custom arrow */
  display: inline-block;
  width: 100%;
  height: calc(1.25em + 1.5rem + 3px);
  padding: 0.75rem 2rem 0.75rem 1rem;
  line-height: 1.25;
  vertical-align: middle;
  background: url("assets/images/icon_dropdown_light.png") right 1rem
    center/10px 8px no-repeat #fff;
  appearance: none;
}
.select-icon-bgSelectDark {
  /* Add custom arrow */
  display: inline-block;
  width: 100%;
  height: calc(1.25em + 1.5rem + 3px);
  padding: 0.75rem 2rem 0.75rem 1rem;
  line-height: 1.25;
  vertical-align: middle;
  background: url("assets/images/icon_dropdown_dark.png") right 1rem center/10px
    8px no-repeat #fff;
  appearance: none;
}

.show-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

/* theme light */
.custom-scroll-light::-webkit-scrollbar:vertical {
  width: 8px;
}
.custom-scroll-light::-webkit-scrollbar {
  -webkit-appearance: none;
}
.custom-scroll-light::-webkit-scrollbar:horizontal {
  height: 0px;
}

.custom-scroll-light::-webkit-scrollbar-thumb {
  background-color: #C4C4C4;
  border-radius: 4px;
  border: 2px solid #ffffff;
}

.custom-scroll-light::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #ffffff;
  border: solid 2px #ffffff;
}
/* theme dark */
.custom-scroll-dark::-webkit-scrollbar:vertical {
  width: 8px;
}

.custom-scroll-dark::-webkit-scrollbar:horizontal {
  height: 0px;
}

.custom-scroll-dark::-webkit-scrollbar-thumb {
  background-color: #282735;
  border-radius: 4px;
  border: 2px solid #353441;
}

.custom-scroll-dark::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color:#353441;
}
/* scroll-dark-home */
.custom-scroll-dark-home::-webkit-scrollbar:vertical {
  width: 8px;
}
.custom-scroll-dark-home::-webkit-scrollbar {
  -webkit-appearance: none;
}

.custom-scroll-dark::-webkit-scrollbar:horizontal {
  height: 0px;
}

.custom-scroll-dark-home::-webkit-scrollbar-thumb {
  background-color: #353441;
  border-radius: 4px;
  border: 2px solid #282735;
}

.custom-scroll-dark-home::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color:#282735;
}
/* ----- */
.disable-scroll::-webkit-scrollbar {
  display: none;
}
