.tabitem {
  border-bottom: 2px solid #d7000f;
  cursor: pointer;
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tabitem--inactive {
  filter: grayscale(30%);
  border: none;
  color: #a8abb8;
}

.tabitem_title {
  /* color: #37333e; */
  font-weight: 700;
}
.tabitem_title_inactive {
  color: #a8abb8;
  font-weight: 700;
}
