.div-input-time-child .react-datepicker-popper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    transform: none !important;
    background-color: rgba(0, 0, 0, 0.5);
}

.div-input-time-child .react-datepicker-popper > div {
    width: 100%;
    padding: 0 15px;
}

.custom-date-picker .react-datepicker__month-container {
    width: 100%;
    font-size: 14px;
}

.custom-date-picker .react-datepicker__day-names, .react-datepicker__week {
    display: flex;
    justify-content: space-around;
    padding: 0 10px;
}

.custom-date-picker .react-datepicker__month-dropdown-container--scroll,
    .react-datepicker__year-dropdown-container--scroll {
    margin: 0 20px !important;
}

.ip-datePicker {
    width: 100%;
    color: inherit;
}

.react-datepicker {
    width: 100%;
}

.fz-date {
    font-size: 14px !important;
}

@media (max-width: 370px) {
    .fz-date {
        font-size: 12.5px !important;
    }
}