.div-content {
    flex: 1;
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.div-detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.margin-right-30 {
    margin-right: 30px !important;
}

.div-option {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    justify-content: center;
    padding: 15px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
}

.div-option p{
    margin: 0;
}

.div-option-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.div-input-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
}

.div-input-time-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ip-datePicker {
    width: 100%;
    color: inherit;
}

.ip-timePicker {
    width: 55px;
}

.view-icon-date-df {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    padding: 5px 3px;
    border-radius: 4px;
    height: 35px;
}

.view-icon-date-dark {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #353441;
    box-sizing: border-box;
    padding: 5px 3px;
    border-radius: 4px;
    height: 35px;
}

.view-icon-date-df img{
    width: 14px;
    height: 14px;
    /* position: absolute;
    margin-left: 72px; */
}

.view-icon-date-dark img{
    width: 14px;
    height: 14px;
    /* position: absolute;
    margin-left: 72px; */
}

.view-icon-time-df {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    padding: 5px 3px;
    border-radius: 4px;
    margin-left: 3px;
    height: 35px;
}

.view-icon-time-dark {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #353441;
    box-sizing: border-box;
    padding: 5px 3px;
    border-radius: 4px;
    margin-left: 3px;
    height: 35px;
}

.view-icon-time-df img{
    width: 14px;
    height: 14px;
    position: absolute;
    margin-left: 39px;
    margin-top: -2px;
}

.view-icon-time-dark img{
    width: 14px;
    height: 14px;
    position: absolute;
    margin-left: 45px;
    margin-top: -2px;
}

.div-input-time-child input {
    border: 0;
}

.div-input-time-child input:focus {
    outline: none;
}

.view-icon-time-df .rc-time-picker .rc-time-picker-clear {
    display: none;
}

.view-them-df .rc-time-picker-input {
    width: 52px;
    background: #F6F7FC !important;
    padding: 0;
    height: auto;
    font-size: unset;
    line-height: 0;
    color: inherit;
}

.view-them-dark .rc-time-picker-input {
    width: 55px;
    background: #353441 !important;
    color: #FFFFFF !important;
    padding: 0;
    height: auto;
    font-size: 14px;
    line-height: 0;
    color: inherit;
}

.mg-note {
    margin: 0 3px;
}

.br-error {
    border: 1px solid #ff4961;
}

/* custom date-picker */
.div-input-time-child .react-datepicker-popper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    transform: none !important;
}

.custom-date-picker .react-datepicker__month-container {
    width: 340px;
    font-size: 14px;
}

.custom-date-picker .react-datepicker__day-names, .react-datepicker__week {
    display: flex;
    justify-content: space-around;
    padding: 0 10px;
}

.custom-date-picker .react-datepicker__month-dropdown-container--scroll,
    .react-datepicker__year-dropdown-container--scroll {
    margin: 0 20px !important;
}
.fz-15x .rc-time-picker-input,.react-datepicker__input-container{
    font-size: 15px !important;
}
@media (min-width: 520px) {
    .view-them-df .rc-time-picker-input {
        width: 100%;
        background: #F6F7FC !important;
        padding: 0;
        height: auto;
        font-size: 14px;
        line-height: 0;
        color: inherit;
    }
    
    .view-them-dark .rc-time-picker-input {
        width: 100%;
        background: #353441 !important;
        color: #FFFFFF !important;
        padding: 0;
        height: auto;
        font-size: 14px;
        line-height: 0;
        color: inherit;
    }

    .view-icon-time-df img{
        position: inherit;
        margin-left: 0;
    }
    
    .view-icon-time-dark img{
        position: inherit;
        margin-left: 0;
    }
}

@media not all and (min-resolution:.001dpcm) { 
    @supports (-webkit-appearance:none) {
        .view-icon-time-df img{
            margin-top: -1px;
        }
        
        .view-icon-time-dark img{
            margin-top: -1px;
        }
    }
}