.header {
    display: flex;
    width: 100%;
    height: 70px;
}

.header-auth {
    display: flex;
    width: 100%;
    height: 70px;
    top: 0px;
}
.register-fixed{
    position: fixed !important;
    position: -webkit-fixed !important;
}
.header-login {
    box-shadow: none !important;
}
.header-login-mhr {
    height: 70px;
    box-shadow: none !important;
}

.header-shadow-df {
    box-shadow: 0px 4px 4px rgba(209, 217, 230, 0.35);
}

.header-shadow-dark {
    box-shadow: 0px -2px 14px 1px rgba(0, 0, 0, 0.35);
}

.header-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    max-width: 720px;
    padding: 10px;
    padding-top: 30px;
    align-items: center;
    justify-content: center;
    position: sticky;
}

.header-content p{
    margin: 0;
}

.header-back {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: absolute;
    padding-left: 10px;
    width: 100%;
    max-width: 720px;
}

.header-back img{
    width: 10px;
    height: 17px;
    margin-right: 8px;
}

.header-back-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fz20 {
    font-size: 20px !important;
    font-weight: 700;
    position: absolute;
}
.fz20-header {
    font-size: 20px !important;
    font-weight: 700;
}
.header-miharas {
    position: absolute;
    width: 100%;
    max-width: 720px;
    /* height: 170px; */
    height: 120px;
    /* margin-top: -80px; */
    margin-top: -50px;
    -webkit-border-radius: 10px / 80px;
    -moz-border-radius: 10px / 80px;
    /* border-radius: 300px / 80px; */
    border-radius: 500px / 80px;
    background: #D7000F;
    z-index: 10;
}

.header-content-df {
    padding-top: 30px;
}

.header-content-miharas {
    /* padding-top: 90px; */
    padding-top: 30px;
    background-color: #D7000F;
}

.title-custom {
    max-width: 60%;
    text-align: center;
    line-height: 20px;
}

/* @media (min-width: 425px) {
    .header-content-miharas {
    }
} */

/* @media (min-width: 520px) {
    .header-content-miharas {
        padding-top: 50px;
    }
} */
/* 
@media (min-width: 590px) {
    .header-miharas {
        position: absolute;
        width: 100%;
        max-width: 720px;
        height: 140px;
        margin-top: -50px;
        -webkit-border-radius: 10px / 80px;
        -moz-border-radius: 10px / 80px;
        border-radius: 500px / 80px;
        background: #D7000F;
        z-index: 10;
    }
} */