.btn-action-add-group{
    margin-top: 1.5rem;
    text-align: center;
}
.btn-action-add-group .btn-ok{
    margin-right: 1rem;
}
.customError p{
    margin-left: unset;
    margin-top: 1rem;
}
.custom-dialog .MuiDialog-paperWidthSm{
    width: 100%;
    position: relative;
    margin: 15px;
    background: none;
    border: none;
    box-shadow: none;
}

.dialog-theme-light{
    background-color: #FFFF;
}
/* .dialog-input{
    margin-bottom: 1rem;
} */
.custom-button-submit-light{
    border: solid 1px #D7000F  !important;
    color: #D7000F  !important;
}
.custom-button-close-light{
    border: solid 1px #D7000F  !important;
    color: #FFFF  !important; 
    background-color: #D7000F  !important;
}
.custom-error{
    color: #D7000F;
    margin-top: 0.5rem;
    font-size: 12px;
}