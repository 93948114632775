.custom-select-lib {
    display: flex;
    align-items: center;
    width: 53%;
}

.custom-select-lib .css-b62m3t-container {
    width: 100%;
}

.custom-select-lib .css-b62m3t-container .css-1s2u09g-control {
    border-width: 0px;
}

.custom-select-lib .css-b62m3t-container .css-1pahdxg-control {
    border-width: 0px;
}

.custom-select-lib .css-b62m3t-container .css-1s2u09g-control .css-1hb7zxy-IndicatorsContainer {
    display: none;
}

.custom-select-lib .css-b62m3t-container .css-1pahdxg-control .css-1hb7zxy-IndicatorsContainer {
    display: none;
}

.custom-select-lib-dark .css-b62m3t-container .css-1s2u09g-control {
    background-color: #353441 !important;
    color: #FFFFFF !important;
}

.custom-select-lib-dark .css-b62m3t-container .css-1pahdxg-control {
    background-color: #353441 !important;
    color: #FFFFFF !important;
}

.custom-select-lib-dark .css-26l3qy-menu {
    background-color: #353441 !important;
    color: #FFFFFF !important;
}

.custom-select-lib-dark .css-b62m3t-container .css-1s2u09g-control .css-319lph-ValueContainer .css-qc6sy-singleValue {
    color: #FFFFFF !important;
}

.custom-select-lib-dark .css-b62m3t-container .css-1pahdxg-control .css-319lph-ValueContainer .css-qc6sy-singleValue {
    color: #FFFFFF !important;
}

.custom-select-lib-dark .css-b62m3t-container .css-1pahdxg-control .css-319lph-ValueContainer .css-1pndypt-Input {
    color: #FFFFFF !important;
}

.txtNote {
    display: flex;
    font-size: 13px;
    color: red;
    margin: -10px 0px 10px;
}