.btn-action-add-group {
  /* padding-top: 1.5rem; */
  text-align: center;
}

.customError p {
  margin-left: unset;
}
.custom-dialog .MuiDialog-paperWidthSm {
  overflow: hidden !important;
  width: 100%;
  position: relative;
  /* margin: 15px; */
  background: none;
  border: none;
  box-shadow: none;
}

.icon {
  margin: 0 auto;
  position: inherit;
  top: 30px;
}
.group-custom-icon {
  width: 68px;
  height: 68px;
  border: solid 2px red;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #ffff;
}
.dialog-theme-light {
  background-color: #ffff;
}

/* .dialog-input {
  margin-bottom: 1rem;
} */
.custom-button-submit-light {
  border: solid 1px #d7000f !important;
  color: #d7000f !important;
}
.btn-focus:hover {
  background-color: thistle;
}
.custom-button-close-light {
  border: solid 1px #d7000f !important;
  color: #ffff !important;
  background-color: #d7000f !important;
}

.txt_input_lightBackground {
  width: 100%;
  padding: 12px;
  outline: none;
  border: 1px solid #f0f0f0 !important;
  border-radius: 4;
}
.txt_input_miharasBackground {
  width: 100%;
  padding: 12px;
  outline: none;
  border: 1px solid #f0f0f0 !important;
  border-radius: 4;
}
.txt_input_darkBackground {
  width: 100%;
  outline: none;
  padding: 12px;
  border-radius: 4;
  border: none;
}

.txt-err {
  color: #d7000f;
  font-size: 12px;
}
.custom-button-close-light button:disabled{
  background-color: #dc454f !important;
}