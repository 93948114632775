.auth-comp {
    height: calc(100% - 70px);
    /* align-items: center; */
    display: flex;
    flex-direction: column;
}

.mb-1 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mt-1 {
    margin-top: 0.5rem !important;
}
.auth-main {
    height: 97vh;
    display: flex;
}

.login-container {
    padding: 16px !important;
}

.input-group > input {
    height: 52px;
    outline: none;
}

.btn-link {
    color: #D7000F !important;
    background-color: transparent;
    border: 1px solid transparent;
}
button.auth {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    height: 52px;
    text-align: center;
    line-height: 0px;
    background-color: #D7000F !important;
    border: 1px solid #D7000F !important;
    border-radius: 6px;
}
button.auth-link {
    color: #20a8d8;
}
button.auth:hover {
    background-color: #D7000F !important;
    border: 1px solid #D7000F !important;
}
button.auth::selection {
    background-color: #D7000F !important;
    border: 1px solid #D7000F !important;
}
button.auth:focus {
    background-color: #D7000F !important;
    border: 1px solid #D7000F !important;
}
button.auth:disabled {
    opacity: 0.6;
}
button.auth-link:hover {
    color: #20a8d8;
}

button.green {
    padding-left: 24px;
    padding-right: 24px;
    width: 56px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4dbd74 !important;
    border: 1px solid #4dbd74 !important;
}

.form-control:focus {
    border: 1px solid #babfc7 !important;
}

.form-check-pass {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.radio_button {
    top: 10px;
    left: 20px;
}

.ic-show-hiden-pw {
    height: 22px;
    width: 22px;
    position: absolute;
    margin-top: -2px;
}

.input-pass {
    outline: none;
    border: 0;
    width: 94%;
}

.input-pass::placeholder {
    color: #BBC0C7;
}

.input-form::placeholder {
    color: #BBC0C7;
}

.input-form {
    display: block;
    width: 100%;
    height: calc(1.25em + 1.5rem + 2px);
    padding: .75rem 1rem;
    line-height: 1.25;
    color: #4e5154;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #F0F0F0;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input-form-dis {
    background-color: #EEEEEE !important;
}

.input-form-dis-dark {
    background-color: #707070 !important;
}

.view-change-langue {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 720px;
    justify-items: center;
    align-items: flex-end;
    flex-direction: column;
    top: -20px;
    margin-bottom: 40px;
    padding: 0px;
}

.view-change-lang-miharas {
    z-index: 20;
    margin-top: -30px;
}

.view-change-langue-ct {
    display: flex;
    justify-items: center;
    align-items: center;
}

.view-change-langue-ct img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.ip-password {
    width: 100%;
    align-items: center;
    height: 52px;
    padding-top: 16px;
}

.view-checkbox {
    align-self: flex-end;
    margin-top: 5px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}

.view-checkbox-df .MuiCheckbox-colorSecondary.Mui-checked {
    color: #D7000F !important;
}

.view-checkbox-df .MuiCheckbox-root {
    color: #37333E !important;
}

.view-checkbox-dark .MuiCheckbox-colorSecondary.Mui-checked {
    color: #D7000F !important;
}

.view-checkbox-dark .MuiCheckbox-root {
    color: #FFFFFF !important;
}

.select-lang {
    border: 0;
    display: inline-block;
    flex-direction: row;
    width: 100%;
    vertical-align: middle;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 40px;
    appearance: none;
    outline: none;
    background-color: transparent !important;
}

.sl-style-df {
    background: url('assets/images/icon_dropdown_light.png') right 1rem center/10px 5px no-repeat #fff;
}

.sl-style-dark {
    color: #FFFFFF;
    appearance: none;
    background: url('assets/images/icon_dropdown_dark.png') right 1rem center/10px 5px no-repeat #fff;
}

.lg-view-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 60px;
    width: 100%;
}

.lg-view-logo img{
    height: 80px;
}

.view-ct {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.view-ct-df {
    padding-top: 25px;
}

.view-ct-miharas {
    padding-top: 20px;
}

/* @media (min-width: 400px) {
    .view-change-lang-miharas {
        padding-top: 40px;
    }
} */

/* @media (min-width: 770px) {
    .view-change-lang-miharas {
        padding-right: 10%;
    }
} */

/* @media (min-width: 600px) {
    .view-change-lang-miharas {
        padding-right: 15%;
    }
} */