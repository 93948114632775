.btn-action-add-group {
  /* padding-top: 1.5rem; */
  text-align: center;
}
.customError p {
  margin-left: unset;
}
.custom-dialog .MuiDialog-paperWidthSm {
  width: 100%;
  position: relative;
  /* margin: 15px; */
  background: none;
  border: none;
  box-shadow: none;
}

.icon {
  margin: 0 auto;
  position: inherit;
  top: 30px;
}
.group-custom-icon {
  width: 60px;
  height: 60px;
  border: solid 2px red;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #ffff;
}
.dialog-theme-light {
  background-color: #ffff;
}
/* .MuiOutlinedInput-input {
  color: white !important;
} */

/* .dialog-input {
  margin-bottom: 1rem;
} */
.custom-button-submit-light {
  border: solid 1px #d7000f !important;
  color: #d7000f !important;
}
.btn-focus:hover {
  background-color: thistle;
}
.custom-button-close-light {
  border: solid 1px #d7000f !important;
  color: #ffff !important;
  background-color: #d7000f !important;
}

.txt_input_lightBackground {
  background-color: white;
}
.txt_input_darkBackground {
  background-color: #353441;
  color: white;
}
