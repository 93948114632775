.regis-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    width: 100%;
}

.regis-view-ip {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.regis-view-fixed {
    align-items: center;
    flex-direction: column;
    position: fixed;
    overflow: auto;
    padding-bottom: 20px;
    width: 100%;
    max-width: 720px;
}
.register-mt{
   margin-top: 70px;
}
.regis-view-df {
    height: calc(100% - 70px);
    padding-top: 0;
}

.regis-view-miharas {
    height: 100%;
    /* padding-top: 50px; */
    padding-bottom: 80px;
}