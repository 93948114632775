.show {
  background-color: #000000;
  margin: 0px;
}
.modal-me {
  background-color: rgba(0, 0, 0, 1);
}

.modal-content {
  border: none;
}

.modal-dialog {
  margin: 0px;
  max-width: none;
}
.custom-imageDialog div:first-child{
  background-color: unset !important;
}