.custom-button-submit-light {
  border: solid 1px #d7000f !important;
  color: #d7000f !important;
}
.btn-focus:hover {
  background-color: thistle;
}
.custom-button-close-light {
  border: solid 1px #d7000f !important;
  color: #ffff !important;
  background-color: #d7000f !important;
}

.txt_input_lightBackground {
  background-color: white;
}
.txt_input_darkBackground {
  background-color: #353441;
  color: white;
}
.dialog-xx::-webkit-scrollbar {
  display: none;
}
.upload-audio{
  display: flex;
  align-items: center;
}
.custom-image-ic-upload{
  width: 48px;
  height: 48px;
}
.mr-0_5re{
  margin-right: 0.5rem;
}