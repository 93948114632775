.btn-cancel{
    width: 50%;
    height: 44px;
    border: 1px solid #D7000F;
    border-radius: 6px;
    color: #D7000F;
    background: #FFFFFF;
    outline: none !important;
    margin-right: 0.5rem;
}
.btn-save{
    width: 50%;
    height: 44px;
    border: 1px solid #D7000F;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #D7000F;
    outline: none !important;
    margin-left: 0.5rem;
}
.btn-close{
    width: 100%;
    height: 44px;
    border: 1px solid #D7000F;
    border-radius: 6px;
    color: #FFFFFF;
    background-color: #D7000F;
    outline: none !important;
}
.form-mt-1_5re{
    margin-top: 1.5rem;
}
.icon{
    margin: 0 auto;
    position: inherit;
    top: 34px;
    z-index: 2;
}
.group-custom-icon{
    width: 60px;
    height: 60px;
    border: solid 2px red;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
}
.backgroundIconThemeDark{
    background: #282735  !important;
}
.backgroundIconThemeLight{
    background: #FFFFFF;
}
.dialogContentBgrDark{
    background: #282735  !important;
}
.borderInput{
    border: '1px solid #E4E9F0';
}
