.node-W__50 {
  width: 50%;
}
.node-W__80 {
  width: 80%;
}
.node-W__20 {
  width: 20%;
}
.node-W__70 {
  width: 70%;
}
.node-W__30 {
  width: 30%;
}
.node-position {
  position: absolute;
  top: 42%;
}
.fs-1_5e {
  font-size: 1.5em;
}
.node-icon-action-pd {
  padding: 0.25em 0.25em;
}
.icon-cls__FF9F0F {
  color: #ff9f0f;
}
.icon-cls__F21100 {
  color: #f21100;
}
.node-fw__600 {
  font-weight: 600;
}
.node-bdr__1x {
  border-right: solid 1px #e5e5e5;
}
.node-ml__0_25e {
  margin-left: 0.25em;
}
.note-border_none {
  border: none;
}
.select-bgr__cls {
  background-color: #f6f7fc !important;
}
.note-custom-select-light {
  /* Add custom arrow */
  display: inline-block;
  width: 100%;
  height: calc(1.25em + 1.5rem + 3px);
  padding: 0.75rem 2rem 0.75rem 1rem;
  line-height: 1.25;
  vertical-align: middle;
  background: url("../../../assets/images/icon_dropdown_light.png") right 1rem
    center/10px 8px no-repeat #fff;
  appearance: none;
  outline: none;
}
.note-custom-select-dark {
  /* Add custom arrow */
  display: inline-block;
  width: 100%;
  height: calc(1.25em + 1.5rem + 3px);
  padding: 0.75rem 2rem 0.75rem 1rem;
  line-height: 1.25;
  vertical-align: middle;
  background: url("../../../assets/images/icon_dropdown_dark.png") right 1rem
    center/10px 8px no-repeat #fff;
  appearance: none;
  outline: none;
}
.pointer-events {
  display: none;
}
.custom-audio .MuiTypography-root {
  margin: 0 auto im !important;
}
.custom-audio .MuiPaper-root {
  background-color: "#E5E5E5 !important";
}
.padding_20x {
  padding: 20px 0px;
}
.list-w_100 {
  height: 100%;
}
.scroll-list {
  /* margin-top: 20px; */
  overflow: auto;
  height: calc(100% - 130px) !important;
}
.scroll-list-user {
  overflow: auto;
  height: calc(100% - 120px);
}
.scroll-list-group {
  overflow: auto;
  height: calc(100% - 50px);
}
.custom-detail-note {
  display: -webkit-box;
  max-height: 50px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.responsive-img-note {
  width: 100%;
  height: 80px;
  object-fit: contain;
}
.responsive-image-audio {
  width: 40px;
  height: 40px;
}
.responsive-ic-edit {
  width: 20px;
  height: 20px;
}
.responsive-ic-delete {
  width: 20px;
  height: 20px;
}
.responsive-img-note-detail {
  width: 100%;
}
.mg_15x {
  margin: 0px 15px;
}
.read-all {
  color: #D7000F;
  text-decoration: underline;
  margin: 10px 0px;
  text-align: right;
  width: 100%;
}
.no-data {
  /* color: #D7000F;
  text-decoration: underline; */
  margin: 10px 0px;
  text-align: right;
  width: 100%;
}
@media screen and (max-width: 539px) {
  .responsive-img-note {
    width: 56px;
    height: 56px;
  }
  .responsive-image-audio {
    width: 32px;
    height: 32px;
  }
  .responsive-ic-edit {
    width: 17px;
    height: 16px;
  }
  .responsive-ic-delete {
    width: 18px;
    height: 18px;
  }
  .responsive-img-note-detail {
    width: 89px;
  }
}
