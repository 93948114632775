

.header-navbar .navbar-container {
    height: 5.15rem;
    display: flex;
    align-items: center;
}

.mgr-20 {
    margin-right: 0px;
}

.main-df {
    height: calc(100% - 70px);
    min-height: calc(100% - 70px); 
    display: flex;
    /* padding-top: 60px; */
}

.padding-left-right_15x{
    padding-left: 15px;
    padding-right: 15px;
}
.unset-margin-row .row{
    margin-left: 0;
    margin-right: 0;
}
.main-df .MuiRadio-colorSecondary.Mui-checked {
    color: #D7000F !important;
}

.set-cl-radio-df .MuiRadio-colorSecondary {
    color: #37333E;
}

.set-cl-radio-dark .MuiRadio-colorSecondary {
    color: #FFFFFF;
}
