.dd-paddingRight {
  padding-right: 2.5px;
}

.chart-bgSelectDf {
  padding: 4px;
  border-radius: 4px;
  width: 100%;
  padding-right: 30px;
  border: 1px solid #e4e9f0;
  background: url("assets/images/icon_dropdown_light.png") right 0.5rem
    center/10px 8px no-repeat #fff;
  appearance: none;
}
.chart-bgSelectDark {
  padding: 4px;
  border-radius: 4px;
  width: 100%;
  padding-right: 30px;
  border: 1px solid #e4e9f0;
  box-sizing: border-box;
  background: url("assets/images/icon_dropdown_dark.png") right 0.5rem
    center/10px 8px no-repeat #fff;
  appearance: none;
}

.chart-btn-update {
  background: none;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px solid #d7000f;
  border-radius: 6px;
  color: #d7000f;
  margin-right: 15px;
}
.responsive_btn_reset{
  margin-top: 10px;
}
.responsive_day{
  display: flex;
}
.responsive_btn_day{
  margin-left: 16px;
}
.responsive{
  display: block;
  margin-left: 20px;
}

@media only screen and (max-width: 573px){
  .responsive {
    display: block !important;
  }
  .responsive_btn_reset{
    margin-top: 10px;
    margin-left: 0px;
  }
  .response_48{
    margin-top: 10px;
  }
  .responsive_btn_day{
    margin-left: 0px;
  }
  .responsive_day{
    display: block;
  }

}

.chart-btn-update:disabled {
  opacity: 0.5;
  background: none !important;
}

.chart-btn-graph-setting {
  background: #d7000f;
  padding-top: 6px;
  padding-bottom: 6px;
  border: 1px solid #d7000f;
  border-radius: 6px;
  color: white;
}

.chart-btn-graph-setting:disabled {
  opacity: 0.5;
  background: #d7000f !important;
}

.fz-14-width{
  width: 105px;
}
.fz-16-width{
  width: 120px;
}
.fz-18-width{
  width: 135px;
}
.chart-fixed::-webkit-scrollbar:horizontal {
  height: 0px;
}

.br-error {
  border: 1px solid #ff4961;
}

.view-date-search {
  font-size: 12px;
  color: dimgray;
  /* padding: 0 5px; */
}

.view-date-search p {
  margin-bottom: 0px;
}