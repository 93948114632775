.div-content-scan {
    flex: 1;
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -15px;
   /* background-color: rgba(0, 0, 0, 0.5); */
}

.div-content-scan-miharas {
    flex: 1;
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
}

.main-fixed-miharas-custom {
    /* height: calc(100% - 20px); */
    height: 100%;
    overflow: auto;
    padding: 100px 0px 20px;
    background-color: rgba(0, 0, 0, 0.5);
}

.div-detail-scan {
    width: 100%;
    /* height: 60vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 15px 0;
}

.div-detail-scan-bg {
    width: 100%;
    /* height: 55vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 15px 0;
    /* background-color: #F6F7FC; */
    /* background-color: rgba(0, 0, 0, 0.5); */
}

.view-btn-scan {
    padding: 70px 15px 0;
    width: 100%;
}

.view-btn-scan .row {
    margin: 0 !important;
}

.view-fix-ct-other {
    padding: 0px;
    height: calc(100% + 40px);
    background-color: rgba(0, 0, 0, 0.5);
    margin: -20px 0;
}

.view-fix-ct-other-miharas {
    padding: 0px;
    height: calc(100% + 20px);
    background-color: transparent;
    margin-top: -20px;
}

.qr-style {
    display: flex;
    justify-content: center;
    position: relative;
}

.qr-style .qr-width {
    width: 100%;
}

.qr-style div{
    border: 50px solid transparent !important;
    box-shadow: #FFFFFF 0px 0px 0px 1px inset !important;
}

.qr-style img {
    position: absolute;
    width: calc(100% - 98px);
    max-width: 355px;
    align-self: center;
}

.paddingDialogContent {
    padding: 32px 16px !important;
    border-radius: 6px
}

.customTy {
    margin-top: 1.5rem !important;
    text-align: center;
}

.TyTextwhite {
    color: #FFFFFF
}

.view-btn-upload {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.btn-upload {
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.3);
    color: #FFFFFF;
    padding: 0 17px;
    border-color: transparent;
}

.btn-upload img {
    height: 25px;
    width: 25px;
    margin-right: 10px;
}

@media (min-width: 501px) { 
    .qr-style .qr-width {
        width: 80%;
    }

    .qr-style img {
        width: calc(80% - 95px);
    }
}

@media (min-width: 601px) { 
    .qr-style .qr-width {
        width: 65%;
    }

    .qr-style img {
        width: calc(65% - 97px);
    }
}

@media (max-height: 810px) {
    .view-fix-ct-other {
        height: auto;
    }
    
    .view-fix-ct-other-miharas {
        height: 100%;
    }
}